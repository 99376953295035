*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    background: url('/static/images/sky.jpg');
    background-position: 100% center;
    background-repeat: repeat;
    animation: loopbg infinite 7200s linear;
}


@keyframes loopbg {
    0% {
      background-position: 5000% center;
    }
    100% {
      background-position: 0% center;
    }
  }